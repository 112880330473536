import React from 'react'
import Slider from "react-slick";
// import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import './cards-slider.scss';


const CardsSlider = ({ items }) => {
    var settings = {
        dots: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        initialSlide: 0,
        infinite: true,
        responsive: [
            {
                breakpoint: 1300,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2,
                    variableWidth: false
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true
                }
            }
        ]
    };
    return (
        <Slider {...settings} >
            {items.map((card, index) => (
                <div key={index + 1} data-index={index}>
                    <div className="slide-card">
                        <h4 className="top-header">{card.title}</h4>
                        <div>
                            {
                                <a href={card.link}>
                                    <h4 className="header">{card.topTitle}</h4>
                                </a>
                            }
                            <h4>{card.secondTitle}</h4>
                        </div>
                        <div className='content'>
                            {/* {documentToReactComponents(card.content)} */}
                            {card.content}
                        </div>
                        {/* <img src={card.image ? card.image : "./icon.png"} alt={card.title} className="card-img" /> */}
                    </div>
                </div>

            ))}

        </Slider>
    );
}
export default CardsSlider