
export const portfolio = {
    name: "Hila ",
    role: "Software Developer",
    email: "",
    phone: "",
    isPhoneDisplay: false,
    isEmailDisplay: true,
    skills: ["Full-Stack developer", "Node.js", "React.js"]
}


export const featuredPortfolio =[]
export const designPortfolio =[]
export const contentPortfolio =[]
export const webPortfolio = [
    {
        id: 1,
        title: "$title",
        img: ""
    }
];

export const mobilePortfolio = [
    {
        id: 1,
        title: "$title",
        img: ""
    }
];