import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import GA4React from "ga-4-react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
 
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <App />
);

try {
  setTimeout(_ => {
    const ga4react = new GA4React(process.env.REACT_APP_ANALYTIC_MEASUREMENT_ID);
    ga4react.initialize().catch(err => console.error(err));
  }, 4000);
} catch (err) {
      // console.error(err);
}