import React from 'react';
import './home.scss';
import PageTitle from '../../layout/pageTitle/PageTitle';
import CardsSlider from '../cardsSlider/CardsSlider';
import SocialLinks from '../about/SocialLinks';
const softwareService = [
  {
    topTitle: "react.js, figma, adobe XD",
    title: "המרה של עיצוב לקוד",
    content: "המרה של עיצובים של Adobr XD או Figma לקוד של HTML & CSS & REACT.JS, אתם מביאים לי את הקוד ואני בונה לכם את האתר",
    technolegy: ""
  },
  {
    topTitle: "react.js",
    title: "בניית אתרים קוד פתוח",
    content: "בניית אתרים קוד פתוח בהתאמה אישית, כולל עיצוב מותאם אליכם, אתרי תדמית לעסק, כרטיסי ביקור דיגיטאלים, אתרים למערכות לעסקים. האתרים כתובים בשפת js עם React.js Html&CSS ",
    technolegy: ""
  },
  {
    topTitle: "node.js, C#, Services & MicroServices",
    title: "בניית שרתים, API ",
    content: "שירות בניית API לעסק ",
    technolegy: ""
  },
  {
    topTitle: "DB, MSSQL Server, Moongos, PostgreSQL",
    title: "בניית דאטה-בייס ",
    content: "לאחסון המידע שלכם בדאטה בייס שאבנה בשבילכם, לעסקים שרוצים לאחסן את המידע של החברה",
    technolegy: ""
  },
  {
    topTitle: "Python ",
    title: "Machine Learning & Data Analyst",
    content: "ניקוי וניתוח טבלאות דאטה, הסקת מסקנות וקבלת החלטות נכונה בעזרת אנליזה, ומודלים של למידת מכונה",
    technolegy: ""
  }
]
const Home = () => {
  return (
    <div id="home" className='home'>
      <PageTitle title={"My Services"} subTitle="רשימת השירותים" />
      <div style={{ width: '80%', display: 'inline-block', margin: '0 auto', height: '700px' }}>
        <CardsSlider items={softwareService} />
        <div className='contact-me'>
          <SocialLinks />
        </div>
      </div>
    </div>
  )
}

export default Home