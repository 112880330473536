import React from 'react'
import './about.scss';
import { technolegy } from './about_data';
import MetaComponent from "../MetaComponent";

const tags = [
    " freelancer ",
    " freelance ", "פרילנסר", " פיתוח תוכנה", " אפליקציות ", " דאטה אנליסט"
]
const About = ({ title = "", description = "" }) => {

    return (
        <>
            {/* <MetaComponent
                title={title}
                description=
                {
                    description + " " +
                    tags.toString() + " " +
                    technolegy.ProgrammingLanguages.toString() + " " +
                    technolegy.Infrastructure.toString() + " " +
                    technolegy.frameworks.toString() + " " +
                    technolegy.databases.toString()
                }

            /> */}

            <div className='about page' id='about'>
                <h1 className='title line'>Technology</h1>
                <div className='skills'>

                    <div>
                        <h3><i className="bi bi-braces-asterisk"></i>Programming Language:</h3>
                        <div className='list-box'>
                            <ul>
                                {
                                    technolegy.ProgrammingLanguages.map((lang, i) => (
                                        <li key={i}>{lang}</li>
                                    ))
                                }
                            </ul>
                        </div>
                    </div>
                    <div>
                        <h3><i className="bi bi-gear-wide-connected"></i>frameworks:</h3>
                        <div className='list-box'>
                            <ul>
                                {
                                    technolegy.frameworks.map((lang, i) => (
                                        <li key={i}>{lang}</li>
                                    ))
                                }
                            </ul>
                        </div>
                    </div>
                    <div>
                        <h3><i className="bi bi-cone-striped"></i>Infrastructure:</h3>
                        <div className='list-box'>
                            <ul>
                                {
                                    technolegy.Infrastructure.map((lang, i) => (
                                        <li key={i}>{lang}</li>
                                    ))
                                }
                            </ul>
                        </div>
                    </div>
                    <div>
                        <h3><i className="bi bi-database"></i> DB:</h3>
                        <div className='list-box'>
                            <ul>
                                {
                                    technolegy.databases.map((lang, i) => (
                                        <li key={i}>{lang}</li>
                                    ))
                                }
                            </ul>
                        </div>
                    </div>
                </div>
                <div className='images-list-auto-scroll' data-options="speed: 2400; autoplay: 1000; items: 4;">
                    <img src="assets/logos/MongoDB_Logo.png" alt="MongoDB Logo" />
                    <img src="assets/logos/React-icon.png" alt="react Logo" />
                    <img src="assets/logos/Microsoft_SQL_server_logo.png" alt="Microsoft SQL server Logo" />
                    <img src="assets/logos/NET_Core_Logo.png" alt="NET_Core_Logo Logo" />
                    <img src="assets/logos/nodejs-logo.png" alt="nodejs Logo" />
                    <img src="assets/logos/postgreSQL.svg" alt="postgreSQL Logo" />
                </div>
            </div>
        </>

    )
}

export default About