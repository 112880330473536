import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

export const technolegy = {
    ProgrammingLanguages: [
        "#C",
        "Python",
        "JavaScript | TypeScript",
        "SQL",
        "HTML & CSS/SASS",
    ],
    frameworks: [
        ".NET CORE",
        "Node.js",
        "Angular 2+",
        "React.js",
    ]
    ,
    Infrastructure: [
        "Azure Devops",
        "AWS",
        "Heroku",
    ],
    databases: [
        "MSSQL Server",
        "PostgreSQL",
        "MongoDB",
    ]
}
export const SocialLinksItems = [
    {
        name: "whatsapp",
        title: "WhatsApp",
        link: "https://wa.me/972528502568?text=%20%F0%9F%91%A9%F0%9F%8F%BB%E2%80%8D%F0%9F%92%BB%D7%90%D7%A9%D7%9E%D7%97%20%D7%A9%D7%AA%D7%A2%D7%96%D7%A8%D7%99%20%D7%9C%D7%99%20%D7%91%D7%91%D7%A0%D7%99%D7%99%D7%AA%20%D7%90%D7%AA%D7%A8%20%D7%9C%D7%A2%D7%A1%D7%A7%20%D7%A9%D7%9C%D7%99",
        icon: <WhatsAppIcon />
    },


    {
        name: "phone_call",
        title: "Phone",
        link: "",
        icon: <LocalPhoneIcon />
    },
    {
        name: "email",
        title: "Email",
        link: "mailto:hilaveksis.service@gmail.com",
        icon: <AlternateEmailIcon />
    }
    ,
    {
        name: "linkedin",
        title: "Linkedin",
        link: "https://www.linkedin.com/in/hila-aveksis-69799510a/",
        icon: <LinkedInIcon />
    }
]