import React from 'react'
import './page-title.scss';

const PageTitle = ({ title, subTitle, style }) => {
  return (
    <div className='title-container'>
      <h1 className='title line' data-aos="zoom-in" style={style}>
        {title}
        <span>{subTitle}</span>
      </h1>

    </div>
  )
}

export default PageTitle